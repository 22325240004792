import React, { Component } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckoutForm from "../components/stripe/CheckoutForm"

const stripePromise = loadStripe("pk_live_O5cmaCfWVG9B732CXicaNdH900EiqyROne");

class Payment extends Component {
  
  render() {
    setTimeout(() => {
    
      document
      .querySelector("#menu")
      .querySelectorAll("li").forEach(li => {
        li.classList.remove('current')
      })
      
      document
      .querySelector("#menu")
      .querySelectorAll("li")[6]
      .classList.add("current");
    }, 2000)

    return (
      <Layout>
        <SEO title="Payer une facture"/>
        <div className="content facture">
            <h1>Payer votre facture en ligne.</h1>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
      </Layout>
    )
  }
}

export default Payment
