import React from "react"
import { useStripe, useElements, CardElement, CardNumberElement } from "@stripe/react-stripe-js"
import CardSection from "./CardSection"

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }

    document.querySelector("input[name=name]").classList.remove("error");
    document.querySelector("input[name=email]").classList.remove("error");
    document.querySelector("input[name=amount]").classList.remove("error");
    document.querySelector(".error-msg").innerHTML = "";
    document.querySelector(".success-msg").innerHTML = "";
    
    let name = document.querySelector("input[name=name]").value;
    let email = document.querySelector("input[name=email]").value;
    let amount = document.querySelector("input[name=amount]").value;
    let facture = document.querySelector("input[name=bill_number]").value;
    let state = true;

    if (name === "") {
      document.querySelector("input[name=name]").classList.add("error");
      state = false;
    }

    if(!validateEmail(email)) {
      document.querySelector("input[name=email]").classList.add("error");
      state = false;
    }

    if (amount === "" || amount <= 0) {
        document.querySelector("input[name=amount]").classList.add("error");
        state = false;
      }
    
    if(facture == undefined || facture == null) {
      facture = "";
    }

    if (state === true) {
      document.querySelector(".payment-button").style.display = "none";
      document.querySelector(".loader").style.display = "block";

      let values = { email: email, amount: amount, facture: facture }
      let intentResponse = await fetch(
        "/scripts/payment.php",
        { method: "POST", body: JSON.stringify(values) }
      )
      let intent = await intentResponse.json()

      const result = await stripe.confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
          },
        },
      })

      document.querySelector(".payment-button").style.display = "block";
      document.querySelector(".loader").style.display = "none";

      if (result.error) {
          document.querySelector(".error-msg").innerHTML = result.error.message;
      } else {
        if (result.paymentIntent.status === "succeeded") {
          document.querySelector("input[name=name]").value = '';
          document.querySelector("input[name=email]").value = "";
          document.querySelector("input[name=amount]").value = "";
          document.querySelector(".error-msg").innerHTML = "";
          document.querySelector("input[name=bill_number]").value = "";
          document.querySelector(".success-msg").innerHTML = "Merci pour votre paiement.<br/> Un mail de confirmation vous sera envoyé dès que le paiement sera confirmé.";
        }
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="error-msg"></div>
      <div className="success-msg"></div>      
      <label>
        E-mail (obligatoire) <input type="email" name="email" />
      </label>
      <label>
        Numéro de facture <input type="text" name="bill_number" />
      </label>
      <label>
        Montant (obligatoire) <input type="text" name="amount" />
      </label>
      <label>
        Titulaire de la carte (obligatoire) <input type="text" name="name" />
      </label>
      <CardSection />
      <button className="payment-button" disabled={!stripe}>Payer</button>
      <span className="loader">Payement en cours...</span>
    </form>
  )
}
