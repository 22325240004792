/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {CardElement, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#c0C2c0",
      },
      backgroundColor: 'transparent'  
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    }
  },
  hidePostalCode: true
};

function CardSection() {
  return (
    <ul className="card-section">
      <li style={{width: '100%'}}>
        <label>
          Numéro de carte (obligatoire) <span className="carte-lbl" style={{float: 'right'}}>Date d'expiration et code de sécurité</span>
          <div className="input-container"><CardElement options={CARD_ELEMENT_OPTIONS}/></div>
        </label>
      </li>
    </ul>
  );
};

export default CardSection;